import axios from "axios";

const instance = axios.create({
  baseURL: "https://node.yahuplus.in/",
   

  // baseURL: "https://nodejsbackend.astrologically.in",
  // baseURL: "https://nodejsbackend.astrologically.in",
  // baseURL: "http://43.205.241.133:4000"
});

export default instance;
